// Basic libraries
import React from "react"
import { Link } from "gatsby"
import { ToastContainer } from "react-toastify"

// Used components
import Navigation from "../../../../components/navigation"
import Content from "../../../../components/content"
import Seo from "../../../../components/seo"
import CurrentUser from "../../../../components/CurrentUser"
import SpacePage from "./index"
import UnityWrapper from "../../../../components/UnityWrapper"

// Images & Styling
import { BackButton } from "../../../../styles/buttons"
import ArrowIcon from "../../../../lib/icons/Arrow"
import { RacerLayout } from "../../../../styles/common"

const GATSBY_ASSET_BASE_URL = process.env.GATSBY_ASSET_BASE_URL
const GAMES_PATH = process.env.GAMES_PATH

// component
class RacerPage extends SpacePage {
  constructor() {
    super()
    this.state = {
      ...this.state,
      unityContext: null,
    }
  }

  componentWillUnmount() {
    this.state.unityContext?.unload()
  }

  render() {
    const windowHeight = this.state.window.height

    return (
      <div>
        <ToastContainer theme="colored" autoClose={2000} />
        <Seo title="Racer" />
        <Navigation>
          <BackButton
            as={Link}
            to="/student/profile/space"
            alt="Back to Space"
            onClick={async () => {
              await this.state.unityContext?.unload()
            }}
          >
            <ArrowIcon />
          </BackButton>
          <CurrentUser />
        </Navigation>
        <Content height={windowHeight}>
          <RacerLayout>
            <UnityWrapper
              buildName="Racer-AUS"
              buildPath={GATSBY_ASSET_BASE_URL + "/" + GAMES_PATH}
              exposeUnityContext={(context) => {
                this.setState({ ...this.state, unityContext: context })
              }}
            />
          </RacerLayout>
        </Content>
      </div>
    )
  }
}
// component-end

// PropTypes
SpacePage.propTypes = {}

export default RacerPage
